@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
body {
  background: rgba(12,18,40,1);
  background: -moz-linear-gradient(top, rgba(12,18,40,1) 0%, rgba(24,26,43,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(12,18,40,1)), color-stop(100%, rgba(24,26,43,1)));
  background: -webkit-linear-gradient(top, rgba(12,18,40,1) 0%, rgba(24,26,43,1) 100%);
  background: -o-linear-gradient(top, rgba(12,18,40,1) 0%, rgba(24,26,43,1) 100%);
  background: -ms-linear-gradient(top, rgba(12,18,40,1) 0%, rgba(24,26,43,1) 100%);
  background: linear-gradient(to bottom, rgba(12,18,40,1) 0%, rgba(24,26,43,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c1228', endColorstr='#181a2b', GradientType=0 );
}
* {
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
