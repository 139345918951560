body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.PhoneInput {
  border: 0.5px solid #ddd;
  padding-left: 16px;
}

.PhoneInputInput {
  height: 100%;
  padding: 16px 0 16px 10px;
  background-color: transparent;
  color: #fff;
  border: none;
  outline: transparent;
}

.PhoneInputCountrySelectArrow {
  color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
}

.menuItems label {
  display: inline-block;
  margin: 0 8px 6px 0;
  width: max-content;
  padding: 3px 8px;
  background-color: #ddd;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
}

.menuItems label:hover {
  background-color: #bbb;
}

.menuItems label.selected {
  background-color: #080;
  color: #fff;
}

.menuItems label.selected:hover {
  background-color: #060;
}

input[type=radio] {
  visibility: hidden;
  margin-left: -16px;
}

.linkTd {
  cursor: pointer;
}

.linkTd:hover {
  color: #00d;
  text-decoration: underline;
}